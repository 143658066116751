// set the service request timeout either from the environment variable or default to 5 seconds
export const AXIOS_TIMEOUT = process.env.REACT_APP_SERVICE_REQUEST_TIMEOUT
  ? parseInt(process.env.REACT_APP_SERVICE_REQUEST_TIMEOUT)
  : 5000;

export const API_BASE = 'http://localhost:8000';

export const PROJECT_DETAILS_ENDPOINT = '${API_BASE}/projectDetails/1';

export const SERVICE_HOST = process.env.REACT_APP_SERVICE_HOST || 'localhost';
export const SERVICE_PORT = process.env.REACT_APP_SERVICE_PORT || '3005';

export const ALERT_DETAILS_ENDPOINT = '/practice/{{0}}/alerts';

export const CARETEAM_ITEMS = '/practice/{{0}}/careTeamConfig';

export const getCareTeamConfig = (showProviders: string, forDashboard: string): string =>
  `/practice/{{0}}/patient/{{1}}/careteam?careTeamProviders=${
    showProviders || 'All'
  }&forDashboard=${forDashboard.toLowerCase()}`;

export const getAppointmentDetailsEndpoint = (
  pastKey: string,
  futureKey: string,
  minutes: string,
  appointmentTypes: string
): string =>
  `/practice/{{0}}/patient/{{1}}/past/${pastKey || 5}/future/${futureKey || 5}/scheduler?minutes=${
    minutes || 120
  }&appointmentTypes=${appointmentTypes}`;

export const PRACTICE_LOCATIONS = '/practice/{{0}}/locations';

export const PRACTICE_INFO = '/practice/{{0}}';
export const HEALTH_DEPT_LOGO = '/practice/{{0}}/logo';

export const PRACTICE_WORKING_HOURS_ENDPOINT = '/practice/{{0}}/workingHours';

/** ---------- eCheckin --------- */

export const personalInfoWithPracticeCalendarIDEndPoint = (practiceCalendarID: string): string =>
  `/practice/{{0}}/patient/{{1}}?practiceCalendarID=${practiceCalendarID}`;

export const PERSONAL_INFO_ENDPOINT = '/practice/{{0}}/patient/{{1}}/';

export const insuranceWithPracticeCalendarIDEndPoint = (practiceCalendarID: string): string =>
  `/practice/{{0}}/patient/{{1}}/insurance?practiceCalendarID=${practiceCalendarID}`;

export const specificInfoWithPracticeCalendarIDEndPoint = (practiceCalendarID: string): string =>
  `/practice/{{0}}/patient/{{1}}/specificInfo?practiceCalendarID=${practiceCalendarID}`;

export const INSURANCE_ENDPOINT = '/practice/{{0}}/patient/{{1}}/insurance';

export const USER_PROFILE_PHOTO = '/practice/{{0}}/patient/{{1}}/profilePhoto';

export const PRACTICES_LIST_ENDPOINT = `/practice/{{0}}/practices`;
export const REQUEST_ACTIVATION_CODE_PRACTICES_LIST_ENDPOINT = `/practices`;

export const STATES_ENDPOINT = '/practice/{{0}}/states';
export const REQUEST_ACTIVATION_CODE_STATES_ENDPOINT = '/states';

export const STATES_WITH_COUNTRYCODE_ENDPOINT = (countryCode: string): string =>
  `/practice/{{0}}/states?countryCode=${countryCode}`;
export const REQUEST_ACTIVATION_CODE_STATES_WITH_COUNTRYCODE_ENDPOINT = (countryCode: string): string =>
  `/states?countryCode=${countryCode}`;

export const COUNTRIES_ENDPOINT = '/practice/{{0}}/countries';
export const REQUEST_ACTIVATION_CODE_COUNTRIES_ENDPOINT = '/countries';

// activation code requests
export const SUBMIT_REQUEST_ACTIVATION = '/submit/activationRequest';
// if user is new, set the flag the user has activated/register his/her account
export const SET_NEW_USER_IS_ACTIVATED_EP = '/practice/{{0}}/patient/{{1}}/Activated';

export const ECHECKIN_SAVE = '/practice/{{0}}/eCheckin';

export const getCancelReqAppointmentAPI = (reqID: string): string => `practice/{{0}}/patient/{{1}}/${reqID}/request`;

export const getUpcomingCancelReqAppointmentAPI = (PracticeCalendarID: string, cancelType: string): string =>
  `practice/{{0}}/patient/{{1}}/${PracticeCalendarID}/appointment?cancelType=${cancelType}`;

export const COUNTY = (value: string): string => `practice/{{0}}/counties/${value}`;
export const REQUEST_ACTIVATION_CODE_COUNTY_ENDPOINT = (value: string): string => `counties/${value}`;
export const SEARCH_INSURANCE = (value: string): string => `/practice/{{0}}/searchInsurance/${value}`;

export const LOOKUPS = '/practice/{{0}}/lookups';
export const QUESTIONNAIRE_LIST = `practice/{{0}}/selectedQuestionnaireList`;

export const RELATIONSHIPS = 'relationships';

export const REIGIONS = 'religions';

/** ---------- eCheckin --------- */

/** ---- Schedule Appointments wizard ---- */

export const ADD_SCHEDULE_APPOINTMENT = `/practice/{{0}}/request`;

export const SCHEDULE_APPOINTMENT_TYPES = `practice/{{0}}/patient/{{1}}/appointmentTypes?category=Appointment`;

export const SCHEDULE_LOCATIONS = `practice/{{0}}/locations`;

export const SCHEDULE_QUESTIONS = `practice/{{0}}/patient/{{1}}/questionnaireContent?questionnaireTemplateIDs={{2}}`;

export const APPOINTMENT_REQUEST_QUESTIONNAIRE = `practice/{{0}}/patient/{{1}}/requestQuestionnaire`;

/** ---- Schedule Appointments wizard ---- */

export const ECHECK_IN_ITEMS_CONFIG = '/practice/{{0}}/eCheckinConfig';

/** ---- Request Appointments in appointments screen ---- */
export const getRequestedAppointmentsEndpoint = `/practice/{{0}}/patient/{{1}}/requests?rowsPerPage={{2}}`;

// Past Appointmnets in appointments screen
export const PAST_APPOINTMENTS_ENDPOINT = `/practice/{{0}}/patient/{{1}}/pastScheduler?minutes={{2}}&rowsPerPage={{3}}`;

// Future Appointmnets in appointments screen
export const FUTURE_APPOINTMENTS_ENDPOINT = `/practice/{{0}}/patient/{{1}}/currentScheduler?minutes={{2}}&rowsPerPage={{3}}`;

// Cancelled Appointments screen
export const CANCELLED_APPOINTMENTS_ENDPOINT = 'practice/{{0}}/patient/{{1}}/cancelledAppointments?rowsPerPage={{2}}';

// Requested Cancellation Appointments screen
export const REQUESTED_CANCELLATION_APPOINTMENTS_ENDPOINT =
  'practice/{{0}}/patient/{{1}}/requestedCancellationAppts?rowsPerPage={{2}}';

// Refused appointments screen
export const REFUSED_APPOINTMENTS_ENDPOINT = 'practice/{{0}}/patient/{{1}}/requestRefused?rowsPerPage={{2}}';

// Admin portal / feature toggle
export const PRACTICE_FEATURES_ENDPOINT = '/practice/{{0}}/features';

export const APPOINTMENTS_CONFIG_ENDPOINT = '/practice/{{0}}/appointmentConfig';

// Admin portal / get appointment type configurations
export const APPOINTMENT_SCHEDULER_CONFIGURATION_ENDPOINT = '/practice/{{0}}/appointmentSchedulerConfig';

// errors
export const TOKEN_EXPIRED = 'Token expired';

// Form.IO endpoints
export const FORM_IO_GET_ENDPOINT = '/practice/{{0}}/patient/{{1}}/form/{{2}}';
export const getMultipleFormsEndpoint = `/practice/{{0}}/patient/{{1}}/forms/list?formNames={{2}}`;
export const FORM_IO_DATA_GET_ENDPOINT = '/practice/{{0}}/patient/{{1}}/form/{{2}}/id/{{3}}';
export const FORM_IO_SAVED_DATA_GET_ENDPOINT = `/practice/{{0}}/patient/{{1}}/appointmentQuestionnaireLists?practiceCalendarID={{2}}&IsFormIO={{3}}`;
export const FORMIO_TRANSLATIONS_ENDPOINT = '/practice/{{0}}/formioTranslations?language={{2}}';

// Signup endpoints
export const CCAToken_ENDPOINT = '/getCCAToken';

/** ---- Questionnaire wizard ---- */
export const PAST_APPOINTMENTS_QUESTIONNAIRES =
  '/practice/{{0}}/patient/{{1}}/pastAppointmentsQuestionnaires?rowsPerPage={{2}}&noofMinutesForFutureAppointments={{3}}';
export const FUTURE_APPOINTMENTS_QUESTIONNAIRES =
  '/practice/{{0}}/patient/{{1}}/futureAppointmentsQuestionnaires?rowsPerPage={{2}}&noofMinutesForFutureAppointments={{3}}';
export const getSaveFormIOQuestionnaireEndpoint = (practiceCalendarID: string, isFormIO: boolean): string => {
  return `/practice/{{0}}/patient/{{1}}/savePatientQuestionnaires?practiceCalendarID=${practiceCalendarID}&isFormIO=${isFormIO}`;
};
export const PAST_APPOINTMENTS_QUESTIONNAIRES_COUNT_ENDPOINT =
  '/practice/{{0}}/patient/{{1}}/pastAppointmentsQuestionnairesCount';

//Message endpoints
export const INBOX_MESSAGES_ENDPOINT = (userID: string, rows: number): string =>
  `/practice/{{0}}/patient/{{1}}/inbox?receiverID=${userID}&rows=${rows}`;
export const COMPOSE_MESSAGE_ENDPOINT = '/practice/{{0}}/patient/{{1}}/sendMessage';
export const DETAILS_MESSAGES_ENDPOINT = (query: string): string =>
  `/practice/{{0}}/patient/{{1}}/detailedMessage?${query}`;
export const UNREAD_MESSAGES_COUNT_ENDPOINT = (userID: string): string =>
  `/practice/{{0}}/patient/{{1}}/unread/${userID}`;

//useSettings
export const USER_SETTINGS = '/practice/{{0}}/userSettings';
//Health Summary endpoints
export const IMMUNIZATIONS_ENDPOINT = '/practice/{{0}}/patient/{{1}}/immunizations';
export const IMMUNIZATION_DETAILS_ENDPOINT =
  '/practice/{{0}}/patient/{{1}}/immunizationsDetails?vaccineCode={{2}}&vaccineName={{3}}';
export const ALLERGIES_ENDPOINT = '/practice/{{0}}/patient/{{1}}/allergies';
export const PROBLEMS_ENDPOINT = '/practice/{{0}}/patient/{{1}}/problems';
export const GET_IMMUNIZATIONS_FOR_PRINT_ENDPOINT = '/practice/{{0}}/patient/{{1}}/vaccinesForPrint?isPDF={{2}}';
// Health Summary -- Vitals
export const VITALS_SUMMARY = '/practice/{{0}}/patient/{{1}}/measureSummary';
export const VITALS_DETAILS = '/practice/{{0}}/patient/{{1}}/measureDetails?patientEncounterID={{2}}&vitalsDate={{3}}';

// get healthSummary config by practice ID
export const PRACTICE_HEALTH_SUMMARY_CONFIG_ENDPOINT = '/practice/{{0}}/healthSummary';

//lab results endpoints
export const LAB_RESULTS_CONFIG = '/practice/{{0}}/testResultConfig';
export const LAB_RESULTS_ENDPOINT = `/practice/{{0}}/patient/{{1}}/labResultsSummry?rowsPerPage={{2}}&labResultsPreference={{3}}`;
export const LAB_RESULTS_DETAILS_ENDPOINT = `/practice/{{0}}/patient/{{1}}/labReultsDetails?labOrderID={{2}}&senderLab={{3}}`;
//Medications
export const MEDICATION_CONFIG = `/practice/{{0}}/medicationConfig`;
export const MEDICATIONS_SUMMARY = (isDiscontinued: boolean): string =>
  `/practice/{{0}}/patient/{{1}}/medicationsSummary?isDiscontinued=${isDiscontinued}`;
export const MEDICATION_DETAILS = `/practice/{{0}}/patient/{{1}}/medicationsDispensedDetails?patientMedicationID={{2}}`;

//Message Config
export const MESSAGE_CONFIG = '/practice/{{0}}/messageConfig';

//Care Team For Send Message
export const CARE_TEAM_FOR_SEND_MESSAGE_ENDPOINT =
  '/practice/{{0}}/patient/{{1}}/careteamformessaging?careTeamProviders={{2}}&messageCareTeam={{3}}&memberIDs={{4}}';

//Visit Records
export const VISIT_RECORDS_ENDPOINT = '/practice/{{0}}/patient/{{1}}/visitRecords?rowsPerPage={{2}}&visitTypes={{3}}';
export const VISIT_RECORD_SUMMARY_ENDPOINT = (patientEncounterID: string): string =>
  `/practice/{{0}}/patient/{{1}}/detailedVisitSummary?patientEncounterID=${patientEncounterID}`;
export const VISIT_RECORDS_PROGRESS_NOTE_ENDPOINT =
  '/practice/{{0}}/patient/{{1}}/patientProgressNote?patientEncounterID={{2}}&userid={{3}}';
export const VISIT_RECORDS_MEASURE_ENDPOINT =
  '/practice/{{0}}/patient/{{1}}/patientMeasure?patientEncounterID={{2}}&userID={{3}}';
export const GET_DETAILED_VISIT_RECORD_ENDPOINT = `/practice/{{0}}/patient/{{1}}/PatientCCDADocument?isPDF={{2}}`;
export const GET_CCDA_XML_FILE_ENDPOINT = '/practice/{{0}}/patient/{{1}}/CCDAXMLDocument';
export const SEND_MAIL_WITH_ATTACHMENT_ENDPOINT =
  '/practice/{{0}}/patient/{{1}}/mailWithAttachment?patientName={{2}}&isDirectMail={{3}}';

// Admin portal / get visit records configurations
export const VISIT_RECORDS_CONFIG = '/practice/{{0}}/visitRecordsConfig';

//Documents
export const HEALTH_INFO_ENDPOINT = (rowsPerPage: number): string =>
  `/practice/{{0}}/patient/{{1}}/healthInfo?rowsPerPage=${rowsPerPage}`;
export const SAVE_HEALTH_INFO_ENDPOINT = '/practice/{{0}}/healthInfo';
export const HEALTH_INFO_DOCUMENTS_ENDPOINT = (rowsPerPage: number): string =>
  `/practice/{{0}}/patient/{{1}}/healthInfoDocuments?rowsPerPage=${rowsPerPage}`;
export const HEALTH_INFO_DOCS_UPLOAD = `/practice/{{0}}/patient/{{1}}/healthInfoDocument`;
export const GET_HEALTH_INFO_DOCUMENT_ENDPOINT = '/practice/{{0}}/healthInfoDocument';
export const GET_SHARED_DOCUMENTS_LIST_ENDPOINT = '/practice/{{0}}/patient/{{1}}/documentsList?rowsPerPage={{2}}';
export const GET_SHARED_DOCUMENT_ENDPOINT = '/practice/{{0}}/sharedDocument';
export const DELETE_UPLOADED_DOCUMENT_ENDPOINT =
  '/practice/{{0}}/patient/{{1}}/deleteUploadedDocument?patientHealthInfoDocID={{2}}&userID={{3}}';

//Kiosk Authentication
export const REACT_APP_KIOSK_TIME_OUT_MS = process.env.REACT_APP_KIOSK_TIME_OUT_MS || 30000;
export const KIOSK_AUTHENTICATION_ENDPOINT = `/auth`;
export const KIOSK_LOGIN = `/practice/{{0}}/kioskLoginVerification?dateOfBirth={{2}}&lastName={{3}}&firstName={{4}}`;
export const KIOSK_GET_APPOINTMENTS = '/practice/{{0}}/patient/{{2}}/appointmentsForKiosk';
export const KIOSK_SAVE_APPOINTMENTS = (isFormIO: boolean): string =>
  `/practice/{{0}}/patient/{{1}}/kioskAppointmentCheckIn?practiceCalendarID={{2}}&userID={{3}}&isFormIO=${isFormIO}`;
export const KIOSK_HEALTH_DEPT_LOGO = '/practice/{{0}}/kiosklogo';
export const KIOSK_ECHECKIN_SAVE = (isFormIO: boolean): string =>
  `/practice/{{0}}/patient/{{1}}/kioskAppointmentCheckIn?practiceCalendarID={{2}}&userID={{3}}&isFormIO=${isFormIO}`;

//Log Patient Aduit
export const LOG_PATIENT_PORTAL_ACCESS = `/practice/{{0}}/logPatientPortalAccess`;

export const PATIENT_AUDIT_LOG = `/practice/{{0}}/patient/{{1}}/portalAuditLogReport?userID={{2}}&fromDate={{3}}&toDate={{4}}&rowsPerPage={{5}}&pageNumber={{6}}`;

//consent forms from EHR
export const EHR_CONSENT_FORM_LIST = '/practice/{{0}}/selectedConsentFormList';
export const EHR_CONSENT_FORMS = '/practice/{{0}}/patient/{{1}}/consentForms?patientFormTemplateIDs={{2}}';
export const EHR_CONSENT_FORMS_DATA_ENDPOINT =
  '/practice/{{0}}/patient/{{1}}/EHRConsentFormsData?patientQuestionnaireIDs={{2}}';
export const EHR_QUESTIONNAIRE_FORMS_DATA_ENDPOINT =
  '/practice/{{0}}/patient/{{1}}/EHRFormsData?practiceCalendarID={{2}}&questionnaireType=Questionnaire&patientQuestionnaireIDs={{3}}';

//self schedule
export const SELF_SCHEDULE_APPOINTMENT_TYPES = '/practice/{{0}}/getSelfScheduleAppointmentTypes';
export const SELF_SCHEDULE_LOCATIONS = '/practice/{{0}}/getSelfScheduleLocations?appointmentType={{2}}';
export const APPOINTMENT_BOOKING_SLOTS_ENDPOINT = '/practice/{{0}}/patient/{{1}}/appointmentBookingSlots?userID={{2}}';
export const APPOINTMENTS_CREATED_IN_ONE_DAY_ENDPOINT =
  '/practice/{{0}}/patient/{{1}}/numberOfApptsCreatedInOneDay?patientUserID={{2}}';
export const APPOINTMENTS_CREATED_IN_CALENDAR_DAY_ENDPOINT =
  '/practice/{{0}}/patient/{{1}}/numberOfApptCreatedInCalendarDay?appointmentDate={{2}}';
export const SCHEDULE_APPOINTMENT_ENDPOINT = '/practice/{{0}}/appointment';
export const SELF_SCHEDULE_SLOTS_ENDPOINT =
  '/practice/{{0}}/patient/{{1}}/selfScheduleSlots?schResourceID={{2}}&locationID={{3}}&appointmentType={{4}}&appointmentDate={{5}}';

export const SELF_SCHEDULE_CALENDAR_ENDPOINT =
  '/practice/{{0}}/selfScheduleCalendar?schResourceID={{2}}&appointmentType={{3}}&locationID={{4}}';

export const TELEHEALTH_LINK_ENDPOINT = '/practice/{{0}}/patient/{{1}}/telehealthLink?isGroupSession={{2}}';

//EHR Configurations
export const EHR_CONFIGURATIONS_ENDPOINT = '/practice/{{0}}/configurations';

// Config for Proxy Access
export const PROXY_ACCESS_CONFIG = '/practice/{{0}}/proxyAccessConfig';

// list proxy access
export const PROXY_ACCESS_LIST = '/practice/{{0}}/patient/{{1}}/getPatientProxyAccessList';

// submit proxy access request
export const REQUEST_PROXY_ACCESS = '/practice/{{0}}/patient/{{1}}/saveProxyPatientRequest';

// Revoke proxy access
export const REVOKE_PROXY_ACCESS = '/practice/{{0}}/saveProxyAccessRequestDetails?practiceID={{2}}&userID={{3}}';

// billing widget => billing details list
export const BILLING_DETAILS_LIST_ENDPOINT = '/practice/{{0}}/patient/{{1}}/billingDetails?phoenixPatientID={{2}}';

//billing widget => payments list
export const PAYMENTS_LIST_ENDPOINT = '/practice/{{0}}/patient/{{1}}/paymentsList?phoenixPatientID={{2}}';

//billing widget => for making the payment
export const ONLINE_TRANSACTION_URL_ENDPOINT = `/practice/{{0}}/patient/{{1}}/onlineTransactionURL`;

//billing widget =>for displaying transaction status after making payment
export const ONLINE_TRANSACTION_DETAILS_ENDPOINT = `/practice/{{0}}/patient/{{1}}/transactionDetails?transactionID={{2}}&isCancelled={{3}}`;

// proxy access requests list

export const PROXY_ACCESS_REQUESTS = '/practice/{{0}}/patient/{{1}}/getPatientProxyListByPatientID';
export const SPECIFIC_INFORMATIOM_ENDPOINT = (practiceCalendarID: string): string => {
  return `/practice/{{0}}/patient/{{1}}/practiceCalendarID/${practiceCalendarID}/SaveSpecificInformation`;
};
